import React from "react";
import Header from "../components/Header";
import styled from "styled-components";
import { navigate } from "gatsby-link";

const SignBackInContainer = styled.div`
  padding-bottom: 100px;
  margin-bottom: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ecebff;
`;

const SignBackInButton = styled.div`
  border-radius: 100px;
  color: rgba(30, 23, 63, 0.6);
  background-color: rgba(30, 23, 63, 0.05);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

  &:hover {
    cursor: pointer;
    background-color: #1e173f;
    color: #ecebff;
  }
`;

const MessageContainer = styled.div `
  margin: 10px;
  text-align: center;
`

function LoggedOut() {
  return (
    <div>
      <Header
        title="Oopsie daisie 😢"
        subheading="You were logged out by another user..."
      />
      <SignBackInContainer>
        <MessageContainer>
          This could have also happened because you forgot to log out of your previous session
        </MessageContainer>
        <SignBackInButton onClick={() => navigate("/login")}>
          Click here to log in 🔐
        </SignBackInButton>
        <br />
        <SignBackInButton
          onClick={() => (window.location = "mailto:hello@oscehub.co.uk")}
        >
          Contact us about the incidence ✉️
        </SignBackInButton>
      </SignBackInContainer>
    </div>
  );
}

export default LoggedOut;
